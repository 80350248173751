<template>
  <div>
    <vx-card style="padding-left: 10px">
      <div class="relative">
        <div class="flex flex-no-wrap w-full mb-2" vs-w="12">
          <div
            class="mr-2"
          >
            <vs-dropdown class="cursor-pointer" vs-custom-content vs-trigger-click>
              <vs-button icon-pack="feather" icon="icon-file-text" :disabled="users.length <= 0 || isButtonDisabled">
                Export
              </vs-button>
              <vs-dropdown-menu>
                <div class="flex flex-col gap-3 px-2 py-1">
                  <ul>
                    <li>
                      <vs-radio v-model="exportMode" vs-value="current" :disabled="isButtonDisabled">
                        Export current table records
                      </vs-radio>
                    </li>
                    <li>
                      <vs-radio v-model="exportMode" vs-value="all" :disabled="isButtonDisabled">
                        Export all records
                      </vs-radio>
                    </li>
                  </ul>
                  <vs-button
                    class="vs-con-loading__container w-full"
                    id="button-with-loading"
                    @click="exportCsvHandler"
                    icon-pack="feather"
                    icon="icon-download"
                    :disabled="users.length <= 0 || isButtonDisabled"
                  >
                    Export as .csv
                  </vs-button>
                </div>
              </vs-dropdown-menu>
            </vs-dropdown>
            <a
              id="downloadCustomerCsv"
              ref="downloadCustomerCSVRef"
              :href="customerCSVurl"
            ></a>
          </div>
          <div class="mr-2 w-full flex justify-end ">
              <assign-emr-doctor @getDoctorsList="getDoctorsList" />
          </div>
        </div>
      </div>
      <div id="data-list-list-view" class="data-list-container">
        <div class="lg:hidden">
          <div class="mb-2 pr-2 flex flex-no-wrap">
            <div class="w-full pr-1">
              <vs-input
                icon="search"
                style="width: 100%"
                placeholder="Search"
                v-model="searchInputString"
              />
            </div>
            <div class="w-24 pl-1">
              <vs-select
                style="width: 100%"
                placeholder="10"
                autocomplete
                v-model="dataTableParams.limit"
              >
                <vs-select-item
                  :key="index"
                  :value="item"
                  :text="item"
                  v-for="(item, index) in limitOptions"
                />
              </vs-select>
            </div>
          </div>
          <ul>
            <li :key="indextr" v-for="(tr, indextr) in users">
              <div
                class="rounded mb-2 p-2 mobile-text"
                style="border: 1px solid #e8e8e8"
              >
                <div class="flex">
                  <h4 class="mr-2 mobile-text">
                    {{
                      users[indextr].firstName
                        ? users[indextr].firstName
                        : "N/A" | capitalize
                    }}
                  </h4>
                  <h4 class="mobile-text">
                    {{
                      users[indextr].lastName
                        ? users[indextr].lastName
                        : "N/A" | capitalize
                    }}
                  </h4>
                </div>
                <!-- action buttons -->

                <div>
                  <p>
                    {{ users[indextr].email }}
                  </p>
                </div>
                <div class="flex">
                  <vs-button
                    style="float: left"
                    type="border"
                    size="small"
                    @click="editDetailHandler(users[indextr]._id)"
                    icon-pack="feather"
                    color="success"
                    class="m-1"
                  >Edit</vs-button>
                  <vs-button
                    style="float: left"
                    type="border"
                    size="small"
                    @click="removeEmrDoctorHandler(data[indextr]._id)"
                    icon-pack="feather"
                    color="danger"
                    class="m-1"
                  >Remove</vs-button>
                </div>
              </div>
            </li>
          </ul>
        </div>

        <vs-table
          class="hidden lg:block"
          ref="table"
          :sst="true"
          :total="totalDocs"
          :data="users"
          @search="search"
          @change-page="handleChangePage"
          @sort="handleSort"
          :max-items="dataTableParams.limit"
          search
          :noDataText="noDataText"
        >
          <div
            slot="header"
            class="flex flex-wrap-reverse flex-grow justify-between"
          >
            <vs-select
              placeholder="10"
              autocomplete
              v-model="dataTableParams.limit"
            >
              <vs-select-item
                :key="index"
                :value="item"
                :text="item"
                v-for="(item, index) in limitOptions"
              />
            </vs-select>
          </div>

          <template slot="thead">
            <vs-th>DP</vs-th>
            <vs-th sort-key="firstName">First Name</vs-th>
            <vs-th sort-key="lastName">Last Name</vs-th>
            <vs-th sort-key="lastName">AP Type</vs-th>
            <vs-th
              v-if="
                ['superAdmin', 'admin'].includes(
                  $store.state.AppActiveUser.userType
                )
              "
              sort-key="doctorType"
              >Type</vs-th
            >
            <vs-th sort-key="email">Email</vs-th>
            <vs-th sort-key="status">Status</vs-th>
            <vs-th>Actions</vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="data[indextr].profileImage">
                <div
                  class="medium-img-wrapper"
                  v-if="data[indextr].profileImage"
                  @click="open_in_new_tab(data[indextr].profileImage)"
                >
                  <img
                    :src="data[indextr].profileImage"
                    class="img-fluid"
                    alt
                  />
                </div>
                <p v-else>N/A</p>
              </vs-td>
              <vs-td :data="data[indextr].firstName">{{
                data[indextr].firstName | capitalize
              }}</vs-td>
              <vs-td :data="data[indextr].firstName">{{
                data[indextr].lastName | capitalize
              }}</vs-td>
              <vs-td :data="data[indextr].firstName">{{
                data[indextr].apType | capitalize
              }}</vs-td>
              <vs-td
                v-if="
                  ['superAdmin', 'admin'].includes(
                    $store.state.AppActiveUser.userType
                  )
                "
                :data="data[indextr].doctorType"
                >{{ data[indextr].doctorType || "contract" }}</vs-td
              >
              <vs-td :data="data[indextr].email">{{
                data[indextr].email
              }}</vs-td>

              <vs-td :data="data[indextr].availabitityStatus">{{
                data[indextr].availabitityStatus
              }}</vs-td>
              <vs-td :data="data[indextr]._id">
                <vs-button
                  style="float: left"
                  type="border"
                  size="small"
                  @click="editDetailHandler(data[indextr]._id)"
                  class="m-1"
                  >Edit</vs-button
                >
                <vs-button
                  style="float: left"
                  color="danger"
                  type="border"
                  size="small"
                  @click="removeEmrDoctorHandler(data[indextr]._id)"
                  class="m-1"
                  >Remove</vs-button
                >
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <div class="m-2">
          <span class="mr-2">
            {{
              dataTableParams.page * dataTableParams.limit -
              (dataTableParams.limit - 1)
            }}
            -
            {{
              totalDocs - dataTableParams.page * dataTableParams.limit > 0
                ? dataTableParams.page * dataTableParams.limit
                : totalDocs
            }}
            of {{ totalDocs }}
          </span>
          <div class="pagination-div" v-if="serverResponded">
            <paginate
              :page-count="totalPage"
              :click-handler="handleChangePage"
              class="pagination"
              :page-range="9"
              :prevText="'<'"
              :nextText="'>'"
            ></paginate>
          </div>
        </div>
      </div>
    </vx-card>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import requestDoctorAssociation from "../../components/others/requestDoctorAssociation";
import inviteUser from "../../components/others/inviteUser";
import assignEmrDoctor from "../../components/emr-doctor/assignEmrDoctor";

export default {
  name: "doctor-table",
  components: {
    "request-doctor": requestDoctorAssociation,
    inviteUser,
    "assign-emr-doctor": assignEmrDoctor,
  },
  props: {
    inTabEdit: {
      type: Boolean,
      default: false,
    },
    canDelete: {
      type: Boolean,
      default: false,
    },
    viewRoute: {
      type: String,
      default: "FranchiseDoctorEdit",
    },
    viewDetailRoute: {
      type: String,
      default: "SuperAdminDoctorDetail",
    },
    canCreate: {
      type: Boolean,
      default: false,
    },
    createRoute: {
      type: String,
      default: "FranchiseDoctorCreate",
    },
    canRequestAssociation: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      noDataText: "Loading..",
      clinicCount: 0,
      customerCSVurl: false,
      searchInputString: null,
      isMounted: false,
      totalDocs: 0,
      dataTableParams: {
        search: "",
        sort: "createdAt",
        dir: "desc",
        page: 1,
        limit: 25,
        filter: "All",
        userType: this.$store.state.AppActiveUser.userType,
        clinicId: this.$route.params.clinicId
      },
      serverResponded: false,
      users: [],
      limitOptions: [5, 10, 25, 50, 100],
      awaitingSearch: null,
      activeClinicId: "All",
      isButtonDisabled: false,
      exportMode: "all",
    };
  },
  methods: {
    ...mapActions("doctor", ["fetchClinicEMRDoctors", "exportCsv"]),
    search(searching) {
      this.searchInputString = searching;
    },
    getDoctorsList() {
      let self = this;
      this.$vs.loading();
      this.fetchClinicEMRDoctors(self.dataTableParams).then((res) => {
        this.$vs.loading.close();
        self.users = res.data.data.docs;
        self.totalDocs = res.data.data.pagination.total;
        self.page = res.data.data.pagination.page;
        self.serverResponded = true;
        if (res.data.data && res.data.data.clinicCount) {
          if (
            this.$store.state.AppActiveUser.userType === "franchise" ||
            this.$store.state.AppActiveUser.userType === "subFranchise"
          )
            self.clinicCount = res.data.data.clinicCount;
          if (
            this.$store.state.AppActiveUser.userType === "clinic" ||
            this.$store.state.AppActiveUser.userType === "subClinic"
          )
            self.clinicCount = 1;
        }
        self.noDataText = "No Doctor Available";
      });
    },
    handleSearch(searching) {
      if (this.awaitingSearch) {
        clearTimeout(this.awaitingSearch);
        this.awaitingSearch = null;
      }
      this.awaitingSearch = setTimeout(() => {
        this.serverResponded = false;
        this.dataTableParams.search = searching;
        this.dataTableParams.page = 1;
        this.$refs.table.currentx = 1;
        this.getDoctorsList();
      }, 500);
    },
    handleChangePage(page) {
      this.dataTableParams.page = page;
      this.getDoctorsList();
    },
    handleSort(key, active) {
      this.serverResponded = false;
      this.dataTableParams.sort = key;
      this.dataTableParams.dir = active;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;
      this.getDoctorsList();
    },
    editDetailHandler(id) {
      this.$router.push({
        name: this.viewRoute,
        params: { doctorId: id, doctorsList: this.users },
      });
    },
    viewDetailHandler(id) {
      this.$router.push({
        name: this.viewDetailRoute,
        params: { doctorId: id },
      });
    },
    createDoctorHandler() {
      this.$router.push({
        name: this.createRoute,
        params: { doctorsList: this.users },
      });
    },
    async removeEmrDoctorHandler(id) {
      try {
        this.$vs.loading()
        await this.$store.dispatch('doctor/removeEmrDoctor', {
          clinicId: this.$route.params.clinicId,
          userId: id
        })
        this.getDoctorsList()
        setTimeout( ()=> {
          this.$vs.loading.close()
        }, 500)
        this.$vs.notify({
          title: 'Success',
          text: 'Doctor removed successfully.',
          color: 'success'
        });
      } catch (error) {
        console.log(error)
        setTimeout( ()=> {
          this.$vs.loading.close()
        }, 500)
        this.$vs.notify({
          title: 'Error',
          text: 'Failed to remove doctor. Try again.',
          color: 'danger'
        });
      }
    },
    createUserHandler() {
      this.$router.push({ name: "SuperAdminCreateUser" });
    },
    exportCsvHandler() {
      this.isButtonDisabled = true;

      this.$vs.loading({
        background: "primary",
        color: "#fff",
        container: `#button-with-loading`,
        scale: 0.45,
      });

      let _instance = this;
      let csvLinkInstance = _instance.$refs.downloadCustomerCSVRef;

      //csvLinkInstance.click();
      this.exportCsv({
        limit: this.dataTableParams.limit,
        page: this.dataTableParams.page,
        exportMode: this.exportMode,
        clinicId: this.dataTableParams.activeClinicId,
        isDoctorEmr: true,
      })
        .then(async (res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "freshclinic-doctors.csv"); //or any other extension
          document.body.appendChild(link);
          link.click();
          this.isButtonDisabled = false;
          this.$vs.loading.close("#button-with-loading > .con-vs-loading");
        })
        .catch((err) => {
          this.isButtonDisabled = false;
          this.$vs.loading.close("#button-with-loading > .con-vs-loading");
          console.log(err);
        });
    },
  },
  computed: {
    totalPage: function () {
      return this.totalDocs / this.dataTableParams.limit >
        parseInt(this.totalDocs / this.dataTableParams.limit)
        ? parseInt(this.totalDocs / this.dataTableParams.limit) + 1
        : parseInt(this.totalDocs / this.dataTableParams.limit);
    },
  },
  watch: {
    searchInputString: function (newVal, oldVal) {
      this.handleSearch(newVal);
    },
    "dataTableParams.page": function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.dataTableParams.page = newVal;
        this.getDoctorsList();
      }
    },
    "dataTableParams.limit": function (newlimit, oldLimit) {
      if (newlimit !== oldLimit) {
        this.serverResponded = false;
        this.dataTableParams.page = 1;
        this.dataTableParams.limit = newlimit;
        this.getDoctorsList();
      }
    },
    "dataTableParams.filter": function (newfilterVal, oldFilterVal) {
      if (newfilterVal !== oldFilterVal) {
        this.serverResponded = false;
        this.dataTableParams.page = 1;
        this.dataTableParams.filter = newfilterVal;
        this.getDoctorsList();
      }
    },
    "$store.state.AppActiveClinicId": function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.dataTableParams.activeClinicId = newVal;
        this.getDoctorsList();
      }
    },
  },
  async created() {
    this.dataTableParams.activeClinicId = (await this.$route.params.clinicId)
      ? this.$route.params.clinicId
      : this.$store.state.AppActiveClinicId;
    this.getDoctorsList();
  },
};
</script>

<style scoped>
.mobile-text {
  color: #164d3d;
}

.vs-dropdown-menu {
  z-index: 99999;
}
</style>
